<template>
  <div
    v-if="details.WarehouseCity"
    class="mb-4"
    id="sh-cd"
    :class="{
      'col-md-12 carousel-item': shipmentShowType === 'dashboard',
      'col-md-6': shipmentShowType !== 'dashboard',
      active: index === activeShipment,
    }"
  >
    <div class="shipmentCard">
      <div class="row">
        <div class="col-md-9">
          <span>
            #{{ details.AdvertNo }} -
            {{ $moment(details ? details.CreatedDateTime : null).format("LL") }}
          </span>
        </div>
        <div class="col-md-3 d-flex align-items-center justify-content-end">
          <img
            src="../../assets/icons/nkicon/detail.svg"
            alt=""
            style="width: 35px; cursor: pointer"
            @click="
              () => {
                $router.push({
                  name: 'sevkiyatlarim.detay',
                  query: { adId: details.ID, status: details.Status },
                });
              }
            "
          />
          <!-- <img v-if="details.Status === this.STATUS.Transporting" src="../../assets/icons/nkicon/track.svg" @click="handleTrackLocation" style="cursor: pointer" alt="" /> -->
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <img src="../../assets/icons/nkicon/locationone.svg" alt="" />
          <span> Yükleme </span>
        </div>
        <div class="col-md-12 d-flex">
          <div class="toDestination"></div>
          <div class="d-flex align-items-start flex-column text-left">
            <!-- <el-button
                class="map-btn"
                @click="handleWarehouseMapDialog"
                type="text"
                >Konumu Görüntüle</el-button
              > -->
            <span style="font-size: 20px">
              {{ details.WarehouseCity }} |
              {{ details.WarehouseDistrict }}
            </span>
            <span class="mt-2" style="font-size: 14px; color: #a6b3c5">
              <!-- {{ details.Warehouse.Address }} -->
            </span>
          </div>
        </div>
        <div class="col-md-12 mt-2">
          <img src="../../assets/icons/nkicon/locationtwo.svg" alt="" />
          <span> İndirme </span>
        </div>
        <div class="col-md-12">
          <div class="d-flex align-items-start flex-column text-left" style="margin-left: 15px">
            <span style="font-size: 20px">
              {{ details.DeliveryCompanyCity }} |
              {{ details.DeliveryCompanyDistrict }}
            </span>
            <span class="mt-2" style="font-size: 14px; color: #a6b3c5">
              <!-- {{ details.DeliveryCompany.Address }} -->
            </span>
          </div>
        </div>
        <div class="col-md-12 mt-2 d-flex align-items-baseline gap-1">
        <img src="../../assets/icons/nkicon/cargo.svg" alt="" />
        <span> Yük </span>
      </div>
      <div class="col-md-12">
        <div
          class="d-flex align-items-start flex-column text-left"
          style="margin-left: 15px"
        >
          <span style="font-size: 20px;">
            {{ details.ProductType }}
          </span>
        </div>
      </div>
        <div class="col-md-12 mt-3">
          <div
            v-if="details.CancellationRequestUserId === user.ID && details.Status !== STATUS.Completed && details.Status !== STATUS.Canceled && details.Status !== STATUS.Passive && (details.CancellationStatus === CANCELLATION_STATUS.Requested || details.CancellationStatus === CANCELLATION_STATUS.Declined)"
            class="
              shipmentStatus
              d-flex
              justify-content-center
              align-items-center
            "
          >
            <span v-if="details.CancellationStatus === CANCELLATION_STATUS.Requested">
              İncelenmektedir
            </span>
            <span v-else-if="details.CancellationStatus === CANCELLATION_STATUS.Declined">
              İptal Reddedildi
            </span>
          </div>
        </div>
        <div class="col-md-12 mt-3">
          <div
            class="
              shipmentStatus
              d-flex
              justify-content-center
              align-items-center
            "
          >
            <span>
              {{ details.Status == STATUS.Delivered && IsCarrier ? "Onay Bekleniyor" : details.StatusName }}
            </span>
          </div>
        </div>

        <div v-if="IsCarrier">
          <div v-if="details.Status === STATUS.Transporting && details.Status !== STATUS.PendingGocPayment" class="row">
            <span class="col-md-6 mt-2">
              <el-button v-if="details.CancellationRequestUserId !== user.ID || (details.CancellationStatus !== CANCELLATION_STATUS.Requested && details.CancellationStatus !== CANCELLATION_STATUS.Declined)" @click="notDeliveredDialog = true" class="rejectButton col-6">
                <span> Teslim Edilmedi </span>
              </el-button>
            </span>
            <span
              class="col-md-6 mt-2"
              :class="{
                'col-md-12': !(details.CancellationRequestUserId !== user.ID || (details.CancellationStatus !== CANCELLATION_STATUS.Requested && details.CancellationStatus !== CANCELLATION_STATUS.Declined)),
              }"
            >
              <el-button @click="deliveredDialog = true" v-if="details.CancellationRequestUserId !== user.ID || details.CancellationStatus !== CANCELLATION_STATUS.Requested" class="approveButton bg-teal">
                <span> Teslim Edildi </span>
              </el-button>
            </span>
          </div>
        </div>
        <div v-else class="mt-1">
          <span class="col-md-12">
            <el-button v-if="details.Status === STATUS.Transporting && (details.CancellationRequestUserId !== user.ID || (details.CancellationStatus !== CANCELLATION_STATUS.Requested && details.CancellationStatus !== CANCELLATION_STATUS.Declined))" @click="handleCancelDialog(details.Status)" class="rejectButton w-100">
              <span> İptal Et </span>
            </el-button>
          </span>
          <span class="col-md-12 mt-2">
            <el-button v-if="details.Status == STATUS.PendingGocPayment" @click="handlePaymentDialog" class="approveButton bg-teal">
              <span>
                Ödeme Yap /
                <span> {{ details.ConfirmedBid.ConfirmedPrice }}</span>
              </span>
            </el-button>
          </span>
          <div v-if="details.Status === STATUS.Delivered" class="row">
            <span class="col-md-6 mt-2">
              <el-button v-if="details.CancellationRequestUserId !== user.ID || (details.CancellationStatus !== CANCELLATION_STATUS.Requested && details.CancellationStatus !== CANCELLATION_STATUS.Declined)" @click="handleCancelDialog(details.Status)" class="rejectButton">
                <span> Teslimat Ulaşmadı </span>
              </el-button>
            </span>
            <span
              class="col-md-6 mt-2"
              :class="{
                'col-md-12': !(details.CancellationRequestUserId !== user.ID || (details.CancellationStatus !== CANCELLATION_STATUS.Requested && details.CancellationStatus !== CANCELLATION_STATUS.Declined)),
              }"
            >
              <el-button v-if="details.Status === STATUS.Delivered" @click="handleComplateShipment" class="approveButton bg-teal">
                <span>Teslimat Ulaştı </span>
              </el-button>
            </span>
          </div>
        </div>
        <div class="row m-0" v-if="!IsCarrier && details.Rate == null && details.Status == STATUS.Completed">
          <div class="col-md-12 mt-2 p-0">
            <el-button @click="ratingDialog = true" class="bg-yellow w-100">
              <span> Değerlendir </span>
            </el-button>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :close-on-click-modal="false" class="sm-modal" title="Sevkiyat İptal" :visible.sync="cancelDialog">
      <p class="text-white">
        Böyle bir durum yaşadığınız için çok üzgünüz. Bizimle, destek@nakliyekullan.com üzerinden iletişime geçebilirsiniz.
      </p>
      <span class="text-blue">İptal talebiniz onaylandığı takdirde işleminiz iptal edilecektir. Eğer butona yanlışlıkla tıkladıysanız bu sayfayı kapatınız.</span>
      <div class="mt-3">
        <label for="">İptal Nedeni</label>
        <textarea
          class="form-control"
          :class="{
            'input-validation-error': cancelResponse.ValidationErrors.some((x) => x.Key == 'Reason'),
          }"
          type="textarea"
          :rows="4"
          minlength="10"
          placeholder="Lütfen İptal Nedenini Giriniz"
          v-model="cancelReason"
        >
        </textarea>
        <span class="validation-error" v-if="cancelResponse.ValidationErrors.some((x) => x.Key == 'Reason')">{{ cancelResponse.ValidationErrors.filter((x) => x.Key == "Reason")[0].Value }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="bg-transparent" @click="cancelDialog = false">Kapat</el-button>
        <el-button class="bg-pink" @click="cancellationRequest(details.ID)">İptal</el-button>
      </span>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" class="sm-modal" title="Teslimat Ulaşmadı" :visible.sync="notArrivedDialog">
      <p class="text-white">
        Böyle bir durum yaşadığınız için çok üzgünüz. Bizimle, destek@nakliyekullan.com üzerinden iletişime geçebilirsiniz.
      </p>
      <span class="text-blue">Teslimatınızın ulaşmadığı bilgisi tarafımıza iletilecek ve doğrulandığı takdirde işleminiz iptal edilecektir. Eğer butona yanlışlıkla tıkladıysanız bu sayfayı kapatınız.</span>
      <div class="mt-3">
        <label for="">Eklemek istedikleriniz</label>
        <textarea
          class="form-control"
          :class="{
            'input-validation-error': cancelResponse.ValidationErrors.some((x) => x.Key == 'Reason'),
          }"
          type="textarea"
          :rows="4"
          minlength="10"
          placeholder="Lütfen Notunuzu Giriniz"
          v-model="cancelReason"
        >
        </textarea>
        <span class="validation-error" v-if="cancelResponse.ValidationErrors.some((x) => x.Key == 'Reason')">{{ cancelResponse.ValidationErrors.filter((x) => x.Key == "Reason")[0].Value }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="bg-transparent" @click="notArrivedDialog = false">Kapat</el-button>
        <el-button class="bg-pink" @click="cancellationRequest(details.ID)">İptal</el-button>
      </span>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" class="sm-modal" title="Teslim Edilmedi" :visible.sync="notDeliveredDialog">
      <p class="text-white">
        Teslimatınızı gerçekleştiremediğiniz için çok üzgünüz. Bizimle, destek@nakliyekullan.com üzerinden iletişime geçebilirsiniz.
      </p>
      <span class="text-blue">Teslim edemediğinize dair talebiniz onaylandığı takdirde teslimatınız iptal edilecektir. Eğer butona yanlışlıkla tıkladıysanız bu sayfayı kapatınız.</span>
      <div class="mt-3">
        <label for="">Teslim Edilememe Nedeni</label>
        <textarea
          class="form-control"
          :class="{
            'input-validation-error': cancelResponse.ValidationErrors.some((x) => x.Key == 'Reason'),
          }"
          type="textarea"
          :rows="4"
          minlength="10"
          placeholder="Lütfen Teslim Edilememe Nedenini Giriniz"
          v-model="cancelReason"
        >
        </textarea>
        <span class="validation-error" v-if="cancelResponse.ValidationErrors.some((x) => x.Key == 'Reason')">{{ cancelResponse.ValidationErrors.filter((x) => x.Key == "Reason")[0].Value }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="bg-transparent" @click="notDeliveredDialog = false">Kapat</el-button>
        <el-button class="bg-pink" @click="cancellationRequest(details.ID)">Teslim Edilmedi</el-button>
      </span>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" class="sm-modal" title="Sevkiyat Onayla" :visible.sync="deliveryConfirmDialog">
      <p class="text-white">Sevkiyatı onaylamak istediğinize emin misiniz?</p>
      <!-- <span class="text-blue">Sevkiyatı onayladığınız takdirde GÖÇ ile yapılan işlemlerde, her iki tarafında onaylaması ile ücret direkt diğer tarafa aktarılır. Tek bir taraf onayladığı takdirde ödeme 72 saat içinde kişiye aktarılır.</span> -->

      <span slot="footer" class="dialog-footer">
        <el-button class="bg-transparent" @click="deliveryConfirmDialog = false">Kapat</el-button>
        <el-button :loading="loading" class="bg-teal" @click="deliveryEnd(details.ID)">Onayla</el-button>
      </span>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" class="sm-modal" title="Teslim Edildi" :visible.sync="deliveredDialog">
      <p class="text-white">
        Teslimatınızı onaylamak istediğinize emin misiniz?
      </p>
      <!-- <span class="text-blue">Teslimat onaylandığı takdirde GÖÇ ile yapılan işlemlerde, her iki tarafında onaylaması ile ücret direkt diğer tarafa aktarılır. Tek bir taraf onayladığı takdirde ödeme 72 saat içinde kişiye aktarılır.</span> -->

      <span slot="footer" class="dialog-footer">
        <el-button class="bg-transparent" @click="deliveredDialog = false">Kapat</el-button>
        <el-button :loading="loading" class="bg-teal" @click="deliveryEnd(details.ID)">Teslim Edildi</el-button>
      </span>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" class="sm-modal" title="Hizmet Değerlendirme" :visible.sync="ratingDialog">
      <div class="mb-3">
        <p class="text-white">Nakliyecinin İletişim Gücü</p>
        <el-rate v-model="rating.Contact"></el-rate>
      </div>
      <span class="validation-error" v-if="ratingResponse.ValidationErrors.some((x) => x.Key == 'Contact')">{{ ratingResponse.ValidationErrors.filter((x) => x.Key == "Contact")[0].Value }}</span>
      <div class="mb-3">
        <p class="text-white">Nakliyecinin Zamanlaması</p>
        <el-rate v-model="rating.Timing"></el-rate>
      </div>
      <span class="validation-error" v-if="ratingResponse.ValidationErrors.some((x) => x.Key == 'Timing')">{{ ratingResponse.ValidationErrors.filter((x) => x.Key == "Timing")[0].Value }}</span>
      <div class="mb-3">
        <p class="text-white">Aynı Nakliyeciyle Çalışmak İsterim</p>
        <el-rate v-model="rating.LiveTracking"></el-rate>
      </div>
      <span class="validation-error" v-if="ratingResponse.ValidationErrors.some((x) => x.Key == 'LiveTracking')">{{ ratingResponse.ValidationErrors.filter((x) => x.Key == "LiveTracking")[0].Value }}</span>
      <div>
        <p class="text-white">Nakliyeciye Yorumunuz</p>
        <textarea class="form-control mt-2" type="textarea" v-model="rating.Comment" :rows="5" placeholder="Lütfen Nakliyeci Hakkındaki Yorumunuzu Giriniz"></textarea>
        <span class="validation-error" v-if="ratingResponse.ValidationErrors.some((x) => x.Key == 'Comment')">{{ ratingResponse.ValidationErrors.filter((x) => x.Key == "Comment")[0].Value }}</span>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button :loading="loading" class="w-100 py-3" @click="commentRate(details.ID)">Gönder</el-button>
      </span>
    </el-dialog>

    <!-- <el-dialog :close-on-click-modal="false" title="Konum" :visible.sync="warehouseMapDialog">
      <GmapMap :center="{ lat: details.Warehouse.Lat, lng: details.Warehouse.Lon }" :zoom="14" style="width: 100%; height: 400px" ref="mapRef">
        <GmapMarker
          :position="{
            lat: details.Warehouse.Lat,
            lng: details.Warehouse.Lon,
          }"
        />
      </GmapMap>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleWarehouseMapDialog">Kapat</el-button>
      </span>
    </el-dialog> -->

    <el-dialog :close-on-click-modal="false" class="sm-modal" title="Ödeme" :before-close="handlePaymentDialogClose" :visible.sync="paymentDialog">
      <span class="text-blue">Bu sayfada verdiğiniz bilgiler hiçbir şekilde sistem tarafından kaydedilmemekte olup, iyzico güvencesiyle alınan ödemelerin sadece ücretleri tarafımıza aktarılmaktadır.</span>

      <div class="row mt-3">
        <div class="col-12 mb-3">
          <label> Kartın Üzerinde Yazan Ad Soyad</label>
          <el-input
            class="form-control"
            :class="{
              'input-validation-error': buyNakuResponse.ValidationErrors.some((x) => x.Key == 'HolderName'),
            }"
            placeholder="Ad Soyad"
            v-model="paymentForm.HolderName"
          ></el-input>
          <span class="validation-error" v-if="buyNakuResponse.ValidationErrors.some((x) => x.Key == 'HolderName')">{{ buyNakuResponse.ValidationErrors.filter((x) => x.Key == "HolderName")[0].Value }}</span>
        </div>
        <div class="col-6 mb-3">
          <label>Ay/Yıl</label>
          <el-input
            class="form-control"
            :class="{
              'input-validation-error': buyNakuResponse.ValidationErrors.some((x) => x.Key == 'ExpiryYear' || x.Key == 'ExpiryMonth'),
            }"
            placeholder="AA/YY"
            v-mask="['##/##']"
            v-model="paymentForm.Expiry"
          >
          </el-input>
          <span class="validation-error" v-if="buyNakuResponse.ValidationErrors.some((x) => x.Key == 'ExpiryYear' || x.Key == 'ExpiryMonth')">{{ buyNakuResponse.ValidationErrors.filter((x) => x.Key == "ExpiryYear" || x.Key == "ExpiryMonth")[0].Value }}</span>
        </div>
        <div class="col-6 mb-3">
          <label>CVV/CVC</label>
          <el-input
            class="form-control"
            :class="{
              'input-validation-error': buyNakuResponse.ValidationErrors.some((x) => x.Key == 'Cvc'),
            }"
            placeholder="***"
            v-mask="['###']"
            v-model="paymentForm.Cvc"
          ></el-input>
          <span class="validation-error" v-if="buyNakuResponse.ValidationErrors.some((x) => x.Key == 'Cvc')">{{ buyNakuResponse.ValidationErrors.filter((x) => x.Key == "Cvc")[0].Value }}</span>
        </div>
        <div class="col-12 mb-3">
          <label>Kart Numarası</label>
          <el-input
            class="form-control"
            :class="{
              'input-validation-error': buyNakuResponse.ValidationErrors.some((x) => x.Key == 'CardNo'),
            }"
            placeholder="____ ____ ____ ____"
            v-mask="['####-####-####-####']"
            v-model="paymentForm.CardNo"
          >
          </el-input>
          <span class="validation-error" v-if="buyNakuResponse.ValidationErrors.some((x) => x.Key == 'CardNo')">{{ buyNakuResponse.ValidationErrors.filter((x) => x.Key == "CardNo")[0].Value }}</span>
        </div>
        <div class="col-12 mb-3 text-white fs-18">
          <small class="text-white">Tutar:</small>
          <strong class="text-white fs-18"> <span class="ms-2"></span>{{ details.ConfirmedBid ? details.ConfirmedBid.ConfirmedPrice : null }}₺ </strong>
        </div>
        <div class="col-12">
          <div class="d-flex">
            <input type="checkbox" @click="handleCheckbox" v-model="contract" class="form-check-input" style="width: 1.25rem !important; height: 1.25rem !important" />
            <label class="form-check-label">
              <span @click="contractDialog = true" class="policy-text">Mesafeli Satış Sözleşmesini</span>
              okudum, anladım kabul ediyorum.</label
            >
          </div>
        </div>
        <div class="col-md-12">
          <img class="w-100 my-3" src="../../assets/images/iyzico-banner.svg" alt="" />
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button :loading="loading" class="py-3 w-100" @click="paymentEnd">Ödeme Yap</el-button>
      </span>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" class="sm-modal" :title="contractInfo.Title" :visible.sync="contractDialog">
      <div v-html="contractInfo.Content"></div>
      <el-button class="accept-button m-0" @click="handleAcceptPolicy"> Kabul et ve kapat</el-button>
    </el-dialog>
    <!-- <el-dialog :close-on-click-modal="false" :before-close="handleTrackLocation" title="Canlı Takip Ekranı" :visible.sync="trackMapDialog">
      <GmapMap :center="center" :zoom="14" style="width: 100%; height: 400px" ref="mapRef">
        <GmapMarker
          :position="{
            lat: marker.position.lat,
            lng: marker.position.lng,
          }"
        />
      </GmapMap>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleTrackLocation">Kapat</el-button>
      </span>
    </el-dialog> -->
    <div id="threed-html" v-html="ThreedHtml"></div>
  </div>
</template>

<script>
import POLICIES from "../../constants/policies";

export default {
  props: ["details", "shipmentShowType", "nativeMapDialog", "index", "activeShipment"],
  data() {
    return {
      locationInterval: null,
      marker: {
        position: {
          lat: 45.508,
          lng: -73.587,
        },
      },
      center: { lat: 45.508, lng: -73.587 },
      mapOptions: {
        disableDefaultUI: true,
      },
      rate1: null,
      rate2: null,
      warehouseMapDialog: false,
      trackMapDialog: false,
      companyMapDialog: false,
      deliveryConfirmDialog: false,
      notArrivedDialog: false,
      ratingDialog: false,
      cancelDialog: false,
      paymentDialog: false,
      notDeliveredDialog: false,
      deliveredDialog: false,
      textarea: "",
      cancelReason: "",
      rating: {
        Comment: "",
        Contact: null,
        Timing: null,
      },
      ThreedHtml: "",
      paymentForm: {
        AdvertId: "",
        HolderName: "",
        CardNo: "",
        ExpiryYear: "",
        ExpiryMonth: "",
        Cvc: "",
        SuccessCallbackUrl: "https://api.nakliyekullan.com/" + "callback/gocpaymentcallback",
        ErrorCallbackUrl: "https://api.nakliyekullan.com/" + "callback/gocpaymentcallback",
        CallbackUrl: "https://api.nakliyekullan.com/" + "callback/gocpaymentcallback",
        FromWeb: true,
        Expiry: "",
      },
      ratingResponse: { ValidationErrors: [] },
      contract: false,
      contractDialog: false,
      buyNakuResponse: { ValidationErrors: [] },
      STATUS: {
        New: 0,
        Transporting: 1,
        Delivered: 2,
        Completed: 3,
        CancelRequest: 4,
        Canceled: 5,
        Passive: 6,
        PendingGocPayment: 7,
      },
      CANCELLATION_STATUS: {
        None: 0,
        Requested: 1,
        Declined: 2,
        Accepted: 3,
        SelfDeclined: 4,
      },
    };
  },
  computed: {
    contractInfo: {
      get() {
        const cookie = this.$store.getters["policies"]?.find((policy) => policy.Type === POLICIES.PAYMENT);
        return cookie ? cookie : { Title: "", Content: "" };
      },
    },
    IsCarrier: {
      get: function() {
        return this.$store.getters["userState"].IsCarrier;
      },
    },
    user: {
      get: function() {
        return this.$store.getters["userState"];
      },
    },
    cancelResponse: {
      get() {
        return this.$store.getters["cancelResponse"];
      },
      set(newValue) {
        this.$store.commit("cancelResponse", newValue);
      },
    },
    advertInfo: {
      get() {
        return this.$store.getters["advertInfo"];
      },
      set(newValue) {
        this.$store.commit("advertInfo", newValue);
      },
    },
    loading() {
      return this.$store.getters["loading"];
    },
  },
  methods: {
    handlePaymentDialogClose() {
      this.paymentDialog = false;
      this.buyNakuResponse = { ValidationErrors: [] };
      this.contract = false;
    },
    handleCheckbox(e) {
      window.sendNotification.warning("Onaylamak için okumanız gerekmektedir");
      e.preventDefault();
      e.stopPropagation();
    },
    handleCancelDialog(status) {
      if (status === 2) this.notArrivedDialog = true;
      else this.cancelDialog = true;
    },
    handleAcceptPolicy() {
      this.contractDialog = false;
      this.contract = true;
    },
    handlePaymentDialog() {
      this.paymentDialog = !this.paymentDialog;
    },
    handleComplateShipment() {
      if (this.details.Status == this.STATUS.PendingGocPayment) {
        window.sendNotification.error("Öncelikle GÖÇ ödemesi yapmalısınız.");
      } else {
        this.deliveryConfirmDialog = !this.deliveryConfirmDialog;
      }
    },
    async changeLocation() {
      const result = await this.fetchData();

      if (result && this.advertInfo && this.advertInfo.User && this.advertInfo.User.Location) {
        const center = {
          lat: this.advertInfo.User.Location.Coordinates[1],
          lng: this.advertInfo.User.Location.Coordinates[0],
        };
        const position = {
          lat: this.advertInfo.User.Location.Coordinates[1],
          lng: this.advertInfo.User.Location.Coordinates[0],
        };
        if (this.nativeMapDialog) {
          this.$store.commit("nativeTrackMapDialog", {
            center: center,
            marker: { position: center },
          });
        } else {
          this.center = center;
          this.marker.position = position;
        }
      }
    },
    async fetchData() {
      let result;
      if (this.details.Status == this.STATUS.Completed) {
        result = await this.$store.dispatch("getCompletedTransferAdverts", {
          AdvertId: this.details.ID,
        });
      } else {
        result = await this.$store.dispatch("getTransferAdverts", {
          AdvertId: this.details.ID,
        });
      }
      return !result.HasError;
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        const center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        if (this.nativeMapDialog) {
          this.$store.commit("nativeTrackMapDialog", {
            center: center,
            marker: { position: center },
          });
        } else {
          this.center = center;
          this.marker.position = center;
        }
      });
    },
    handleTrackLocation() {
      if (this.nativeMapDialog) {
        const nativeTrackMapDialog = this.$store.getters["nativeTrackMapDialog"].trackMapDialog;
        // this.geolocate();
        this.changeLocation();
        this.$store.commit("nativeTrackMapDialog", {
          locationInterval: setInterval(() => {
            this.changeLocation();
          }, 30000),
        });
        this.$store.commit("nativeTrackMapDialog", {
          trackMapDialog: !nativeTrackMapDialog,
        });
      } else {
        if (!this.trackMapDialog) {
          // this.geolocate();
          this.changeLocation();
          this.locationInterval = setInterval(() => {
            this.changeLocation();
          }, 30000);
        } else {
          clearInterval(this.locationInterval);
        }
        this.trackMapDialog = !this.trackMapDialog;
      }
    },
    handleWarehouseMapDialog() {
      if (this.nativeMapDialog) {
        const warehouseMapDialog = this.$store.getters["nativeWHMapDialog"].warehouseMapDialog;
        this.$store.commit("nativeWHMapDialog", {
          lat: this.details.Warehouse.Lat,
          lng: this.details.Warehouse.Lon,
          warehouseMapDialog: !warehouseMapDialog,
        });
      } else {
        this.warehouseMapDialog = !this.warehouseMapDialog;
      }
    },
    handleCompanyMapDialog() {
      if (this.nativeMapDialog) {
        const companyMapDialog = this.$store.getters["nativeCMapDialog"].companyMapDialog;
        this.$store.commit("nativeCMapDialog", {
          lat: this.details.DeliveryCompany.Lat,
          lng: this.details.DeliveryCompany.Lon,
          companyMapDialog: !companyMapDialog,
        });
      } else {
        this.companyMapDialog = !this.companyMapDialog;
      }
    },
    async cancellationRequest(ID) {
      const result = await this.$store.dispatch("sendCancellationRequest", {
        AdvertId: ID,
        Reason: this.cancelReason,
      });
      if (!result.HasError) {
        this.cancelDialog = false;
        this.notArrivedDialog = false;
        this.notDeliveredDialog = false;
      }
    },
    async deliveryEnd(ID) {
      const result = await this.$store.dispatch("completeAdvert", {
        AdvertId: ID,
      });

      if (!result.HasError) {
        this.deliveryConfirmDialog = false;
        this.deliveredDialog = false;
      }
    },
    async commentRate(ID) {
      const result = await this.$store.dispatch("commentRate", {
        AdvertId: ID,
        ...this.rating,
      });
      if (!result.HasError) this.ratingDialog = false;
      else this.ratingResponse = result;
    },
    complateShipment() {
      if (this.details.Status == this.STATUS.PendingGocPayment) {
        window.sendNotification.error("Öncelikle GÖÇ ödemesi yapmalısınız.");
      } else {
        this.deliveryConfirmDialog = true;
      }
    },
    async paymentEnd() {
      if (!this.contract) {
        window.sendNotification.error("Devam etmek için sözleşmeyi kabul edin!");
        return;
      }
      this.paymentForm.CardNo = this.paymentForm.CardNo.replace("-", "")
        .replace("-", "")
        .replace("-", "")
        .replace("-", "");
      if (this.paymentForm.Expiry && this.paymentForm.Expiry !== "") {
        this.paymentForm.ExpiryMonth = this.paymentForm.Expiry.substring(0, 2);
        this.paymentForm.ExpiryYear = this.paymentForm.Expiry.substring(3);
      }

      this.paymentForm.AdvertId = this.details.ID;

      const result = await this.$store.dispatch("doPaymentAdvert", this.paymentForm);

      if (result.Data && result.Data.ThreeD) {
        this.ThreedHtml = result.Data.ThreedHtml;
        this.paymentDialog = false;
        window.$("body").css("background-color", "var(--bs-dark)");
        this.$store.dispatch("iyzico3DForm");
      } else if (result.ValidationErrors && result.ValidationErrors.length > 0) {
        this.buyNakuResponse = result;
      }
    },
  },
};
</script>

<style>
#threed-html {
  visibility: hidden;
}
.el-rate {
  height: 40px;
}
.el-rate__icon {
  font-size: 40px;
}
.shipmentCard {
  background-color: #4d668a;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  padding: 7px 15px 7px 15px;
  animation: fadeIn 0.3s;
  -webkit-animation: fadeIn 0.3s;
  -moz-animation: fadeIn 0.3s;
  -o-animation: fadeIn 0.3s;
  -ms-animation: fadeIn 0.3s;
}
.shipmentStatus {
  background-color: #2c3f59;
  height: 45px;
  width: 100%;
  border-radius: 16px;
}
.rejectButton {
  background-color: #ff6978;
  height: 45px;
  width: 100%;
  border-radius: 8px;
  border: none;
  color: white;
  margin-top: 4px;
  margin-right: 4px;
}
.approveButton {
  height: 45px;
  width: 100%;
  border-radius: 8px;
  border: none;
  color: white;
  margin-top: 4px;
}

.rejectButton:hover,
.rejectButton:focus {
  border: none;
  background-color: #ff6978;
}

.approveButton:hover,
.approveButton:focus {
  border: none;
  background-color: #0cce6b;
}
.toDestination {
  margin-left: 4px;
  margin-right: 10px;
  border-left: 2px dashed white;
  height: 100%;
}
.map-btn span {
  color: #f6bd60;
}
</style>
